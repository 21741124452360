@charset "UTF-8";
@font-face {
  font-family: "NanumSquareRound";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: auto !important; }

body {
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  font-family: NanumSquareRound;
  word-break: keep-all; }

#root {
  height: 100%; }

.app {
  display: flex;
  flex-direction: column; }

/*=============================================
=            모바일            =
=============================================*/
@media screen and (max-width: 1089px) {
  /*background image*/
  .modal-content-body {
    height: 350px;
    text-align: center;
    padding-top: 70px;
    color: #1562a6; }
  .page-top-image-wrapper {
    position: fixed;
    bottom: 20px;
    right: 10px; }
  .page-top-image {
    width: 50px; }
  .background-main {
    background: no-repeat url("../img/1/background.png");
    background-size: cover; }
  .background-sub1 {
    background: no-repeat url("../img/2/background.png");
    background-size: cover; }
  .background-sub1-item1 {
    background: no-repeat url("../img/2/grid1.png");
    background-size: cover;
    opacity: 0.8; }
  .background-sub1-item1:hover {
    background: no-repeat url("../img/2/grid1.png");
    background-size: cover;
    opacity: 1; }
  .background-sub1-item2 {
    background: no-repeat url("../img/2/grid2.png");
    background-size: cover;
    opacity: 0.8; }
  .background-sub1-item2:hover {
    background: no-repeat url("../img/2/grid2.png");
    background-size: cover;
    opacity: 1; }
  .background-sub1-item3 {
    background: no-repeat url("../img/2/grid3.png");
    background-size: cover;
    opacity: 0.8; }
  .background-sub1-item3:hover {
    background: no-repeat url("../img/2/grid3.png");
    background-size: cover;
    opacity: 1; }
  .background-sub1-item4 {
    background: no-repeat url("../img/2/grid4.png");
    background-size: cover;
    opacity: 0.8; }
  .background-sub1-item4:hover {
    background: no-repeat url("../img/2/grid4.png");
    background-size: cover;
    opacity: 1; }
  .background-sub1-item5 {
    background: no-repeat url("../img/2/grid5.png");
    background-size: cover;
    opacity: 0.8; }
  .background-sub1-item5:hover {
    background: no-repeat url("../img/2/grid5.png");
    background-size: cover;
    opacity: 1; }
  .background-sub1-item6 {
    background: no-repeat url("../img/2/grid6.png");
    background-size: cover;
    opacity: 0.8; }
  .background-sub1-item6:hover {
    background: no-repeat url("../img/2/grid6.png");
    background-size: cover;
    opacity: 1; }
  .background-sub2 {
    background: no-repeat url("../img/3/background.png");
    background-size: auto 840px;
    background-color: #f5f6f8; }
  .background-sub2-content {
    background: no-repeat url("../img/3/diagram.png");
    background-size: contain; }
  .background-sub3-right {
    background: no-repeat url("../img/4/img.png");
    z-index: 100; }
  .background-sub4 {
    width: 100%;
    background-color: #f5f6f8; }
  .background-sub5 {
    background: no-repeat url("../img/6/m_background.png");
    background-size: cover; }
  .desk {
    display: none; }
  .invisible {
    display: none; }
  /* modal */
  .modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    overflow: auto;
    /* Enable scroll if needed */
    background-color: black;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    /* The Close Button */
    /* Modal Content/Box */ }
    .modal .close {
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      top: -15px;
      right: -30px;
      z-index: 100; }
    .modal .close:hover,
    .modal .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer; }
    .modal-content {
      max-width: 250px;
      height: 304px;
      background-color: #fefefe;
      border-radius: 5px;
      margin: 30% auto;
      /* 15% from the top and centered */
      padding: 20px 25px;
      border: 1px solid #888;
      position: relative;
      font-weight: bold; }
      .modal-content-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #bddbe8;
        margin-bottom: 25px; }
        .modal-content-header .clicked {
          border-bottom: 3px solid #0c81e6;
          padding-bottom: 25px; }
        .modal-content-header-item {
          cursor: pointer;
          display: flex;
          flex-direction: column; }
          .modal-content-header-item span:nth-child(1) {
            font-size: 12px; }
          .modal-content-header-item span:nth-child(2) {
            font-size: 16px; }
    .modal-content-qna {
      z-index: 100;
      max-width: 80%;
      height: auto;
      border-radius: 5px;
      background-color: #fff;
      margin: 5% auto;
      padding: 40px 40px;
      border: 1px solid #888;
      font-weight: bold; }
      .modal-content-qna-header {
        display: flex;
        justify-content: space-between; }
  /*container*/
  .container {
    max-width: 360px;
    margin: 0 auto;
    height: auto;
    padding: 0 15px; }
  /*header*/
  .navigation-container {
    height: 84px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 40px; }
  .navigation-list {
    display: none; }
  /* main */
  .main {
    padding-bottom: 150px; }
    .main h1 {
      color: aqua;
      margin-bottom: 40px; }
    .main h4 {
      color: white;
      margin-bottom: 40px; }
    .main-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative; }
      .main-content-left {
        margin-bottom: 100px; }
        .main-content-left-btn {
          display: flex;
          justify-content: center; }
          .main-content-left-btn-item {
            display: flex;
            width: 120px;
            height: 45px;
            text-align: center;
            border: 1px solid white;
            margin-right: 7px;
            align-items: center;
            justify-content: center;
            cursor: pointer; }
            .main-content-left-btn-item span {
              padding: 0px;
              color: #fff; }
          .main-content-left-btn-item-clicked {
            display: flex;
            width: 120px;
            height: 45px;
            text-align: center;
            border: 1px solid transparent;
            background-color: #fff;
            margin-right: 7px;
            align-items: center;
            justify-content: center;
            cursor: pointer; }
            .main-content-left-btn-item-clicked span {
              padding: 0px;
              color: deepskyblue; }
      .main-content-right {
        align-items: center; }
        .main-content-right .main-image-container {
          width: 330px;
          height: 330px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative; }
          .main-content-right .main-image-container .main-img1 {
            display: none; }
          .main-content-right .main-image-container .main-img2 {
            border-radius: 20px;
            box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1); }
        .main-content-right .main-image-logo {
          position: absolute; }
      .main-content .main-bottom-img {
        display: none; }
  .sub1 {
    padding-bottom: 100px; }
    .sub1-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px; }
      .sub1-header-title {
        width: 285px;
        flex: 0.25;
        margin-bottom: 40px;
        margin-top: 40px; }
        .sub1-header-title span {
          font-size: 30px;
          color: #fff; }
      .sub1-header-subtitle {
        flex: 0.75; }
        .sub1-header-subtitle span {
          font-size: 16px;
          color: #fff; }
    .sub1-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 190px 190px 190px; }
      .sub1-content-item {
        border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 16px; }
        .sub1-content-item-icon {
          display: flex;
          justify-content: flex-end; }
        .sub1-content-item-description {
          display: flex;
          flex-direction: column; }
          .sub1-content-item-description span {
            color: #fff; }
          .sub1-content-item-description-title {
            font-size: 12px; }
          .sub1-content-item-description-sub {
            font-size: 16px;
            font-weight: bold; }
  .sub2 {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    line-break: anywhere; }
    .sub2-header {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
      .sub2-header-container {
        background-color: #485f72;
        flex: 0.9;
        color: #fff;
        padding: 20px 25px 20px; }
      .sub2-header-title {
        font-size: 24px;
        margin-bottom: 17px; }
      .sub2-header-subtitle {
        font-size: 16px;
        word-break: break-word; }
    .sub2-content {
      max-width: 360px;
      margin: 0 auto; }
      .sub2-content-image {
        max-width: 100%;
        height: auto; }
  .sub3-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .sub3-container .sub3-title {
      line-height: 100px; }
    .sub3-container .sub3 {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      height: auto; }
      .sub3-container .sub3-table-row {
        display: flex;
        flex-direction: column;
        border-bottom: #d3dae9 solid 1px;
        padding-bottom: 25px;
        margin-bottom: 25px; }
        .sub3-container .sub3-table-row-col1 {
          margin-bottom: 20px; }
          .sub3-container .sub3-table-row-col1-container {
            background-color: #f5f6f8;
            border-radius: 30px;
            width: 92px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .sub3-container .sub3-table-row-col1-container span {
              vertical-align: middle;
              color: #0c81e6;
              font-size: 16px;
              font-weight: bold; }
        .sub3-container .sub3-table-row-col2 {
          font-size: 16px; }
          .sub3-container .sub3-table-row-col2-add {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
          .sub3-container .sub3-table-row-col2-circle {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 55px;
            margin-top: 25px; }
            .sub3-container .sub3-table-row-col2-circle-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 55px;
              height: 55px;
              border-radius: 50%;
              background-color: #e0e5f0;
              margin-bottom: 7px; }
            .sub3-container .sub3-table-row-col2-circle span {
              font-size: 12px;
              text-align: center; }
      .sub3-container .sub3-right {
        max-width: 360px; }
        .sub3-container .sub3-right-img {
          width: 100%;
          height: auto; }
  .sub4 {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 auto; }
    .sub4-left {
      flex: 0.39;
      display: flex;
      justify-content: center;
      align-items: center; }
      .sub4-left-container {
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;
        max-width: 360px; }
        .sub4-left-container-background {
          width: 100%;
          height: auto; }
    .sub4-right {
      padding: 0 15px;
      flex: 0.71;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2093ed;
      color: #fff;
      padding-bottom: 50px; }
      .sub4-right-container {
        max-width: 360px; }
      .sub4-right-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        line-height: 110px; }
        .sub4-right-header-title span {
          align-self: flex-end; }
        .sub4-right-header-title-icon {
          width: 87px;
          height: 86px;
          background-color: #256fa9;
          display: flex;
          justify-content: center;
          align-items: center; }
      .sub4-right-header-subtitle {
        margin-bottom: 40px;
        font-size: 16px; }
      .sub4-right-table-row {
        margin-bottom: 20px;
        display: flex;
        flex: 1;
        align-items: center; }
        .sub4-right-table-row-col1 {
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .sub4-right-table-row-col1 img {
            margin-bottom: 12px; }
        .sub4-right-table-row-col2 {
          margin-left: 30px;
          font-size: 16px; }
          .sub4-right-table-row-col2 span {
            line-height: 130%; }
  .sub5 {
    color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 360px;
    padding-bottom: 50px;
    margin: 0 auto;
    flex: 1; }
    .sub5-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 65px;
      padding-bottom: 65px; }
      .sub5-header-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 30px; }
    .sub5-content {
      display: flex;
      flex-direction: column; }
      .sub5-content-row {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 30px; }
        .sub5-content-row-icon {
          flex: 0.35;
          width: 88px; }
        .sub5-content-row-item {
          flex: 0.65; }
          .sub5-content-row-item-title {
            font-size: 22px;
            font-weight: bold;
            color: #1562a6;
            margin-bottom: 10px; }
          .sub5-content-row-item-des {
            font-size: 16px; }
  .sub6 {
    height: 100%; }
    .sub6-header-container {
      position: relative; }
    .sub6-header {
      max-width: 381px;
      margin: 0 auto;
      line-height: 84px;
      position: relative;
      display: flex;
      align-items: flex-end; }
      .sub6-header h1 {
        margin: 0; }
      .sub6-header-create-btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0px;
        width: 78px;
        height: 78px;
        background-color: #2093ed;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
    .sub6-table {
      text-align: center;
      width: 100%;
      table-layout: fixed; }
      .sub6-table td {
        padding: 20px 10px; }
      .sub6-table-header {
        line-height: 68px; }
        .sub6-table-header-head {
          color: #3ca0ee;
          font-size: 16px;
          font-weight: bold;
          padding: 0px; }
      .sub6-table-body-row {
        background-color: #fff;
        border: 1px solid #bddbe8;
        border-top: 1px solid #2093ed;
        border-collapse: collapse; }
        .sub6-table-body-row-title {
          cursor: pointer;
          text-align: left;
          padding-left: 10px;
          padding-right: 10px; }
          .sub6-table-body-row-title span {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
      .sub6-table-body-expandleRow {
        text-align: left; }
        .sub6-table-body-expandleRow td {
          padding-left: 25px;
          padding-right: 0px; }
        .sub6-table-body-expandleRow-question {
          position: relative;
          display: flex;
          justify-content: space-between; }
        .sub6-table-body-expandleRow-icon {
          margin-right: 20px; }
        .sub6-table-body-expandleRow-answer {
          background-color: #e7ebf5;
          padding: 35px 20px;
          display: flex;
          align-items: center; }
          .sub6-table-body-expandleRow-answer p {
            margin: 0; }
  #footer {
    background-color: #2a2a2a;
    min-height: 400px; }
    #footer span {
      color: #fff; }
    #footer .footer-container {
      width: 100%;
      height: 380px;
      padding-top: 39px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: column; }
      #footer .footer-container .company-info {
        padding-bottom: 45px; }
        #footer .footer-container .company-info .blobs-coin-title {
          color: #4d5261;
          font-size: 16px; }
        #footer .footer-container .company-info span {
          font-size: 12px; }
      #footer .footer-container .right .link-img {
        width: 32px; }
      #footer .footer-container .right .iso-img {
        width: 32px; }
      #footer .footer-container .right .association-img {
        width: 116px; } }

/*=============================================
=           데스크탑            =
=============================================*/
@media screen and (min-width: 1090px) {
  /*background image*/
  .content-summary-text {
    font-size: 23px;
    color: #04509b;
    line-height: 30px;
    padding: 30px 0px; }
  .content-text {
    font-size: 18px; }
  .content-image {
    padding: 20px 0px;
    text-align: center; }
  .modal-content-body {
    overflow-y: scroll;
    height: 550px; }
  .page-top-image-wrapper {
    position: fixed;
    bottom: 150px;
    right: 100px; }
  .background {
    height: 840px; }
    .background-main {
      background: no-repeat url("../img/1/background.png");
      background-size: cover; }
    .background-sub1 {
      background: no-repeat url("../img/2/background.png");
      background-size: cover; }
    .background-sub1-item1 {
      background: no-repeat url("../img/2/grid1.png");
      background-size: cover;
      opacity: 0.8; }
    .background-sub1-item1:hover {
      background: no-repeat url("../img/2/grid1.png");
      background-size: cover;
      opacity: 1; }
    .background-sub1-item2 {
      background: no-repeat url("../img/2/grid2.png");
      background-size: cover;
      opacity: 0.8; }
    .background-sub1-item2:hover {
      background: no-repeat url("../img/2/grid2.png");
      background-size: cover;
      opacity: 1; }
    .background-sub1-item3 {
      background: no-repeat url("../img/2/grid3.png");
      background-size: cover;
      opacity: 0.7; }
    .background-sub1-item3:hover {
      background: no-repeat url("../img/2/grid3.png");
      background-size: cover;
      opacity: 1; }
    .background-sub1-item4 {
      background: no-repeat url("../img/2/grid4.png");
      background-size: cover;
      opacity: 0.8; }
    .background-sub1-item4:hover {
      background: no-repeat url("../img/2/grid4.png");
      background-size: cover;
      opacity: 1; }
    .background-sub1-item5 {
      background: no-repeat url("../img/2/grid5.png");
      background-size: cover;
      opacity: 0.8; }
    .background-sub1-item5:hover {
      background: no-repeat url("../img/2/grid5.png");
      background-size: cover;
      opacity: 1; }
    .background-sub1-item6 {
      background: no-repeat url("../img/2/grid6.png");
      background-size: cover;
      opacity: 0.7; }
    .background-sub1-item6:hover {
      background: no-repeat url("../img/2/grid6.png");
      background-size: cover;
      opacity: 1; }
    .background-sub2 {
      background: no-repeat url("../img/3/background.png");
      background-size: auto 840px;
      background-color: #f5f6f8; }
    .background-sub2-content {
      background: no-repeat url("../img/3/diagram.png");
      background-size: contain; }
    .background-sub3-right {
      background: no-repeat url("../img/4/img.png");
      z-index: 100; }
    .background-sub4 {
      width: 100%;
      background-color: #f5f6f8; }
    .background-sub5 {
      background: no-repeat url("../img/6/background.png");
      background-size: cover; }
  .mobile {
    display: none; }
  .app {
    position: relative; }
  .invisible {
    display: none; }
  /* modal */
  .modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    overflow: auto;
    /* Enable scroll if needed */
    background-color: black;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    /* The Close Button */
    /* Modal Content/Box */ }
    .modal .close {
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      top: -15px;
      right: -30px; }
    .modal .close:hover,
    .modal .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer; }
    .modal-content {
      z-index: 100;
      max-width: 60%;
      height: 724px;
      border-radius: 5px;
      background-color: #fefefe;
      margin: 5% auto;
      /* 15% from the top and centered */
      padding: 40px 50px;
      border: 1px solid #888;
      position: relative;
      font-weight: bold; }
      .modal-content-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #bddbe8;
        margin-bottom: 25px; }
        .modal-content-header .clicked {
          border-bottom: 3px solid #0c81e6;
          padding-bottom: 25px; }
        .modal-content-header-item {
          cursor: pointer;
          display: flex;
          flex-direction: column; }
          .modal-content-header-item span:nth-child(1) {
            font-size: 12px; }
          .modal-content-header-item span:nth-child(2) {
            font-size: 16px; }
    .modal-content-qna {
      z-index: 100;
      max-width: 40%;
      height: 80%;
      border-radius: 5px;
      background-color: #fff;
      margin: 5% auto;
      padding: 40px 50px;
      border: 1px solid #888;
      font-weight: bold; }
      .modal-content-qna-header {
        display: flex;
        justify-content: space-between; }
  /*container*/
  .container {
    max-width: 1090px;
    height: auto;
    margin: 0 auto; }
  .non-width-container {
    height: 840px;
    margin: 0 auto; }
  .sticky-icon {
    display: none;
    position: absolute;
    top: 115px;
    right: 50px; }
    .sticky-icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      margin-bottom: 15px; }
      .sticky-icon-container .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background-color: #fff; }
  /* header */
  .navigation {
    line-height: 84px; }
    .navigation-container {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .navigation-list {
      display: flex;
      margin: 0; }
      .navigation-list-item {
        text-align: center;
        margin-left: 20px;
        list-style: none; }
      .navigation-list-item a {
        color: white;
        text-decoration: none;
        padding-bottom: 5px; }
      .navigation-list-item :hover {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
  /*main*/
  .main h1 {
    color: aqua;
    margin-bottom: 50px; }
  .main h4 {
    color: white;
    margin-bottom: 50px;
    padding-right: 220px; }
  .main-content {
    height: calc(840px - 84px);
    display: flex;
    align-items: center;
    position: relative; }
    .main-content-left-btn {
      display: flex; }
      .main-content-left-btn-item {
        display: flex;
        width: 130px;
        height: 50px;
        text-align: center;
        border: 1px solid white;
        margin-right: 7px;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .main-content-left-btn-item span {
          padding: 0px;
          color: #fff; }
      .main-content-left-btn-item-clicked {
        display: flex;
        width: 130px;
        height: 50px;
        text-align: center;
        border: 1px solid transparent;
        background-color: #fff;
        margin-right: 7px;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .main-content-left-btn-item-clicked span {
          padding: 0px;
          color: deepskyblue; }
    .main-content-right {
      align-items: center; }
      .main-content-right .main-image-container {
        border-radius: 50%;
        background-color: #fff;
        width: 500px;
        height: 500px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative; }
        .main-content-right .main-image-container .main-img1 {
          border-radius: 20px;
          box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1); }
        .main-content-right .main-image-container .main-img2 {
          display: none; }
      .main-content-right .main-image-logo {
        position: absolute; }
    .main-content .main-bottom-img {
      position: absolute;
      bottom: 40px;
      left: calc(1090px / 2);
      justify-content: center;
      align-items: center; }
  .sub1 {
    height: 840px; }
    .sub1-header {
      height: 200px;
      display: flex;
      align-items: center; }
      .sub1-header-title {
        width: 285px;
        flex: 0.25; }
        .sub1-header-title span {
          font-size: 30px;
          color: #fff; }
      .sub1-header-subtitle {
        flex: 0.75; }
        .sub1-header-subtitle span {
          font-size: 16px;
          color: #fff; }
    .sub1-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 290px 290px;
      cursor: pointer; }
      .sub1-content-item {
        border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 40px; }
        .sub1-content-item-icon {
          display: flex;
          justify-content: flex-end; }
        .sub1-content-item-description {
          display: flex;
          flex-direction: column; }
          .sub1-content-item-description span {
            color: #fff; }
          .sub1-content-item-description-title {
            font-size: 16px; }
          .sub1-content-item-description-sub {
            font-size: 22px;
            font-weight: bold; }
  .sub2 {
    display: flex;
    flex-direction: column;
    align-content: center; }
    .sub2-header {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
      .sub2-header-container {
        background-color: #485f72;
        flex: 0.5;
        color: #fff;
        padding: 20px 25px 20px; }
      .sub2-header-title {
        font-size: 24px;
        margin-bottom: 17px; }
      .sub2-header-subtitle {
        font-size: 16px;
        word-break: break-word; }
    .sub2-content {
      max-width: 1090px;
      margin: 0 auto;
      padding-top: 60px;
      flex: 1; }
      .sub2-content-image {
        max-width: 100%;
        height: auto; }
  .sub3-container {
    display: flex;
    flex-direction: column;
    height: 840px; }
    .sub3-container .sub3-title {
      line-height: 84px; }
      .sub3-container .sub3-title span {
        font-size: 24px; }
    .sub3-container .sub3 {
      display: flex;
      max-width: 1090px;
      height: auto; } }
    @media screen and (min-width: 1090px) and (max-width: 1300px) {
      .sub3-container .sub3 {
        overflow: scroll; } }

@media screen and (min-width: 1090px) {
      .sub3-container .sub3-table {
        width: 65%;
        margin-right: 40px;
        position: relative;
        padding-top: 10px; }
        .sub3-container .sub3-table-row {
          display: flex;
          border-bottom: #d3dae9 solid 1px;
          padding-bottom: 60px;
          margin-bottom: 60px; }
          .sub3-container .sub3-table-row-col1 {
            flex: 0.2;
            width: 150px; }
            .sub3-container .sub3-table-row-col1-container span {
              background-color: #f5f6f8;
              border-radius: 30px;
              padding: 8px 12px;
              color: #0c81e6;
              font-size: 16px;
              font-weight: bold; }
          .sub3-container .sub3-table-row-col2 {
            flex: 0.8;
            font-size: 16px; }
            .sub3-container .sub3-table-row-col2-add {
              display: flex;
              flex-direction: row;
              justify-content: space-between; }
            .sub3-container .sub3-table-row-col2-circle {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 77px;
              margin-top: 60px; }
              .sub3-container .sub3-table-row-col2-circle-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 77px;
                height: 77px;
                border-radius: 50%;
                background-color: #e0e5f0;
                margin-bottom: 7px; }
              .sub3-container .sub3-table-row-col2-circle span {
                font-size: 12px;
                text-align: center;
                width: 60px; }
      .sub3-container .sub3-right {
        width: 35%;
        position: relative; }
        .sub3-container .sub3-right-img {
          position: absolute; }
  .sub4 {
    display: flex;
    width: 1090px;
    height: 627px;
    margin: 0 auto; }
    .sub4-left {
      flex: 0.39; }
      .sub4-left-container {
        padding-top: 30px;
        height: 570px;
        position: relative; }
        .sub4-left-container-absolute-img {
          position: absolute;
          right: -50px;
          bottom: 0; }
    .sub4-right {
      flex: 0.71;
      padding-left: 70px;
      background-color: #2093ed;
      color: #fff; }
      .sub4-right-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        line-height: 110px; }
        .sub4-right-header-title-icon {
          width: 87px;
          height: 86px;
          background-color: #256fa9;
          display: flex;
          justify-content: center;
          align-items: center; }
      .sub4-right-header-subtitle {
        margin-bottom: 40px;
        padding-right: 110px;
        font-size: 16px; }
      .sub4-right-table-row {
        margin-bottom: 20px;
        display: flex;
        flex: 1;
        align-items: center; }
        .sub4-right-table-row-col1 {
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .sub4-right-table-row-col1 img {
            margin-bottom: 12px; }
        .sub4-right-table-row-col2 {
          margin-left: 30px;
          margin-right: 70px;
          font-size: 16px; }
          .sub4-right-table-row-col2 span {
            line-height: 130%; }
  .sub5 {
    color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 1090px;
    height: 950px;
    margin: 0 auto;
    flex: 1; }
    .sub5-header {
      flex: 0.15;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center; }
      .sub5-header-title {
        font-size: 24px;
        font-weight: bold; }
    .sub5-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 0.75; }
      .sub5-content-row {
        max-width: calc(910px / 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .sub5-content-row-icon {
          height: 220px;
          display: flex;
          justify-content: center;
          align-items: center; }
        .sub5-content-row-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .sub5-content-row-item-title {
            font-size: 22px;
            font-weight: bold;
            color: #1562a6;
            margin-bottom: 15px; }
          .sub5-content-row-item-des {
            text-align: center;
            font-size: 16px; }
  .sub6 {
    max-width: 1090px;
    height: 100%;
    margin: 0 auto; }
    .sub6-header {
      line-height: 110px; }
    .sub6-table {
      text-align: center;
      width: 100%;
      table-layout: auto; }
      .sub6-table td {
        padding: 30px 10px; }
      .sub6-table-header {
        line-height: 78px; }
        .sub6-table-header-head {
          color: #3ca0ee;
          font-size: 16px;
          font-weight: bold;
          padding: 0px; }
        .sub6-table-header-create-btn {
          padding: 0px;
          width: 78px;
          height: 78px;
          background-color: #2093ed;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
      .sub6-table-body-row {
        background-color: #fff;
        border: 1px solid #bddbe8;
        border-top: 1px solid #2093ed;
        border-collapse: collapse; }
        .sub6-table-body-row-title {
          cursor: pointer;
          text-align: left; }
      .sub6-table-body-expandleRow {
        text-align: left; }
        .sub6-table-body-expandleRow td {
          padding-left: 25px;
          padding-right: 0px; }
        .sub6-table-body-expandleRow-question {
          position: relative;
          display: flex;
          justify-content: space-between; }
        .sub6-table-body-expandleRow-icon {
          margin-right: 20px; }
        .sub6-table-body-expandleRow-answer {
          background-color: #e7ebf5;
          padding: 35px 20px;
          display: flex;
          align-items: center; }
          .sub6-table-body-expandleRow-answer p {
            margin: 0; }
  #footer {
    background-color: #2a2a2a; }
    #footer span {
      color: #fff; }
    #footer .footer-container {
      width: 100%;
      min-height: 380px;
      padding-top: 68px;
      display: flex;
      justify-content: space-between; }
      #footer .footer-container .company-info {
        flex: 0.7; }
        #footer .footer-container .company-info .blobs-coin-title {
          color: #4d5261;
          font-size: 20px; }
      #footer .footer-container .right {
        flex: 0.3; } }
